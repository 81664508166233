import { FC, useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { ChatDrawer, ChatConversation, ChatInput } from './chat';
import { IConversationDetails } from '../models/IAppointment';
import useMessagesCache from '../hooks/use-messages-cache';
import StartConversationIcon from './icons/StartConversationIcon';
import { ICustomer } from '../models/ICustomer';
import customerAPI from '../services/CustomerService';

const CONVERSATION_SELECTION_ENABLED = false;

type MessagesComponentProps = {
    customer: Pick<ICustomer, 'id' | 'phone'>;
};

const MessagesComponent: FC<MessagesComponentProps> = ({ customer }) => {
    const { updateMessagesCache } = useMessagesCache();
    const [selectedConversation, setSelectedConversation] = useState<number | null>(null);
    const { data: conversationsList, isLoading: listLoading } = customerAPI.useGetConversationsListQuery(customer.id ?? skipToken);

    const conversationId = useMemo(() => (conversationsList ? conversationsList[0]?.id : undefined), [conversationsList]);

    const {
        data: conversation,
        isLoading: conversationLoading,
        isFetching: conversationFetching
    } = customerAPI.useGetSingleConversationQuery(
        conversationId
            ? {
                  customerId: customer.id,
                  conversationId
              }
            : skipToken
    );

    const customerPhone = customer.phone;
    const customerId = customer.id;

    useEffect(() => {
        const handleUpdate = (payload?: { conversation?: IConversationDetails | null } | null) => {
            if (payload?.conversation && customerId) {
                updateMessagesCache(customerId, payload.conversation);
            }
        };
        window.Echo.private(`App.Messaging.Customer.${customerId}`).listen('.messaging.conversation.created', handleUpdate);

        return () => {
            window.Echo.private(`App.Messaging.Customer.${customerId}`).stopListening('.messaging.conversation.created');
            window.Echo.leave(`App.Messaging.Customer.${customerId}`);
        };
    }, [customerId, updateMessagesCache]);

    if (listLoading) {
        return (
            <Box sx={{ flexGrow: 1, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!listLoading && !customerPhone) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                <Typography variant="h3" align="center" sx={{ my: 'auto', px: 3, color: 'primary.900' }}>
                    Customer has no phone number.
                </Typography>
            </Box>
        );
    }

    if (conversationsList?.length && !listLoading) {
        return (
            <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                {CONVERSATION_SELECTION_ENABLED ? (
                    <>
                        <ChatDrawer
                            conversations={conversationsList}
                            selectedId={selectedConversation}
                            onSelectConversation={setSelectedConversation}
                        />
                        <Divider orientation="vertical" sx={{ height: '100%' }} />
                    </>
                ) : null}

                {conversationId ? (
                    <ChatConversation
                        conversation={conversation}
                        isLoading={conversationLoading || conversationFetching}
                        customer={customer}
                    />
                ) : (
                    <Box>Please select conversation</Box>
                )}
            </Box>
        );
    }

    if (conversationsList && !conversationsList.length && !listLoading) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                <Stack spacing={3} sx={{ my: 'auto', color: 'primary.900', alignItems: 'center' }}>
                    <StartConversationIcon />
                    <Typography variant="h3" align="center" sx={{ px: 3, color: 'primary.900' }}>
                        Start a conversation!
                    </Typography>
                </Stack>
                <ChatInput customerId={customerId} />
            </Box>
        );
    }

    return null;
};

export default MessagesComponent;
