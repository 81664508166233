import { FC, ReactElement, useState } from 'react';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { Box, Button, ButtonBase } from '@mui/material';
import CBModal from '../../../../../../../ui-component/cb-modal/CBModal';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ErrorCatcher from '../../../../../../../ui-component/error-catcher';
import { SnackBarTypes } from '../../../../../../../store/snackbarReducer';
import useShowSnackbar from '../../../../../../../hooks/useShowSnackbar';

export type SquareUpFormProps = {
    application_id: string;
    location_id: string;
    onTokenReceive: (token: string) => void;
    ToggleBtn?: ReactElement;
};

const SquareUpForm: FC<SquareUpFormProps> = ({ application_id, location_id, onTokenReceive, ToggleBtn }) => {
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const { showSnackbar } = useShowSnackbar();
    return (
        <>
            {ToggleBtn ? (
                <ButtonBase sx={{ mb: 1.5, width: '100%' }} onClick={() => setOpen(true)}>
                    {ToggleBtn}
                </ButtonBase>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                    fullWidth
                    sx={{ height: '45px', mb: 1.5 }}
                    startIcon={<CreditCardIcon />}
                >
                    Square
                </Button>
            )}
            <CBModal
                open={open}
                onClose={() => setOpen(false)}
                title={<Box px={3}>Payment Details</Box>}
                fullWidth
                maxWidth="sm"
                fullScreen={matchSm}
            >
                <ErrorCatcher wrappedComponentName="Square PaymentForm">
                    {open && (
                        <PaymentForm
                            applicationId={application_id}
                            locationId={location_id}
                            cardTokenizeResponseReceived={(token) => {
                                if (token.errors) {
                                    const errorsText = token.errors.map((err) => err.message).join('; ');
                                    showSnackbar({
                                        alertSeverity: SnackBarTypes.Error,
                                        message: errorsText
                                    });
                                    console.error(token.errors);
                                } else {
                                    token.token && onTokenReceive(token.token);
                                }
                            }}
                        >
                            <CreditCard />
                        </PaymentForm>
                    )}
                </ErrorCatcher>
            </CBModal>
        </>
    );
};

export default SquareUpForm;
