import { IUser } from './IUser';
import { ILocation, ISchedule } from './ILocation';
import { IService } from './IService';
import { ImageData } from './IImage';
import { ICompany, PaymentGateways } from './ICompany';
import { IProduct } from './IProduct';

export interface IEmployeeLocation extends ILocation {
    pivot: {
        use_location_schedule: boolean;
        is_shifts_enabled: boolean;
        schedule: ISchedule[];
        shifts: CustomShift[];
    };
}

export enum EmployeeCommissionType {
    Fixed = 'fixed',
    Percent = 'percent'
}

export type EmployeeServiceType = IService & {
    pivot?: {
        price?: number | null;
        duration: number | null;
        commission_type: EmployeeCommissionType | null;
        commission_amount: number | null;
    };
};

export type EmployeeProductType = Pick<IProduct, 'id'> & {
    pivot?: {
        commission_type: EmployeeCommissionType | null;
        commission_amount: number | null;
    };
};

export interface EmployeeData {
    id: number;
    title: string;
    profession_title?: string | null;
    locations: IEmployeeLocation[];
    services: EmployeeServiceType[];
    products: EmployeeProductType[];
    background_color: string;
    text_color: string;
    self_book?: boolean;
    status: EmployeeStatus;
    slug: string;
    avatar: ImageData | null;
    settings?: IEmployeeSettings;
}

export interface IEmployee extends EmployeeData {
    locations_count?: number;
    user: IUser;
    deleted_at: string;
    role: UserRole;
    isActive?: boolean;
    isWorking?: boolean;
    is_invite_accepted?: boolean;
    payment_gws?: PaymentGateways;
}

export enum EmployeeStatus {
    Active = 'active',
    Pending = 'pending'
}

export enum UserRole {
    Owner = 'owner',
    Admin = 'admin',
    Manager = 'manager',
    Provider = 'provider',
    FrontDesk = 'frontdesk',
    ReadOnlyLimited = 'read_only_limited'
}

export interface IEmployeePayload {
    id?: IEmployee['id'];
    user: {
        firstname: string;
        lastname: string;
        email: string;
        phone: string;
    };
    role: IEmployee['role'];
    profession_title: string;
    services: { id: number; pivot: { price: number | null } }[];
    background_color: string;
    text_color: string;
    location_shifts: Array<{
        id: number;
        pivot: {
            use_location_schedule: boolean;
            is_shifts_enabled: boolean;
            schedule: ISchedule[];
            shifts: CustomShift[];
        };
    }>;
}

export interface IEmployeeResendInvitationPayload {
    id: IEmployee['id'];
    email?: IEmployee['user']['email'];
}

export interface CustomShift {
    opened: boolean;
    start: string;
    end: string;
    label?: string | null;
}

export interface IGroupedCustomShift {
    startTime: string;
    endTime: string;
    label?: string | null;
    shifts: string[];
    opened: boolean;
}

export interface IEmployeeIntegrations {
    use_provider_payment_details: boolean;
}

export interface IEmployeeCalendarSettings {
    cell_duration: number;
    show_scheduled_staff: boolean;
    show_canceled_appointments: boolean;
    selected_location_id: number;
    locations: IEmployeeLocationSettings[] | null;
    show_archived_staff: boolean;
}

export interface IEmployeeSettings {
    widget?: {
        accounting_google_events: boolean;
    };
    calendar: IEmployeeCalendarSettings;
    integrations?: IEmployeeIntegrations;
}

export type EmployeeCalendarSettings = Omit<IEmployeeCalendarSettings, 'cell_duration'>;

export interface IEmployeeLocationSettings {
    id: number;
    services: number[];
    employees: number[];
}

export type EmployeeWidgetCompany = Pick<ICompany, 'email' | 'logo' | 'name' | 'phone' | 'settings' | 'slug'>;
export type EmployeeWidgetLocation = Pick<
    IEmployeeLocation,
    'address' | 'id' | 'name' | 'phone' | 'pivot' | 'schedule' | 'shifts' | 'slug' | 'time_zone' | 'is_shifts_enabled' | 'services'
>;

export interface IWidgetEmployeeData {
    avatar: string | ImageData | null;
    company: EmployeeWidgetCompany;
    id: number;
    slug: string;
    location_ids: number[];
    locations: EmployeeWidgetLocation[];
    service_ids: number[];
}

export interface IEmployeeWidgetResponse {
    id: number;
    avatar: string | ImageData | null;
    slug: string;
    user: {
        id: number;
        firstname: string;
        lastname: string;
        avatar: string | ImageData | null;
        employees: Array<IWidgetEmployeeData>;
        title: string;
    };
}
