import { IService } from '../models/IService';
import { useCallback, useMemo, useState } from 'react';

const useAcceptServicePolicies = (services: IService[]) => {
    const [accepted, setAccepted] = useState<number[]>([]);

    const onToggleAccept = useCallback((newValue: boolean, serviceId: number) => {
        setAccepted((prev) => {
            if (newValue) {
                return [...prev, serviceId];
            }

            return prev.filter((v) => v !== serviceId);
        });
    }, []);

    const servicesWithPolicy = useMemo(() => services.filter((s) => s.use_service_policy.enabled), [services]);

    const allServicesAccepted = useMemo(
        () => !servicesWithPolicy.length || servicesWithPolicy.every(({ id }) => accepted.includes(id)),
        [accepted, servicesWithPolicy]
    );

    return { accepted, servicesWithPolicy, onToggleAccept, allServicesAccepted };
};

export default useAcceptServicePolicies;
