import { useMemo } from 'react';
import useAuth from './useAuth';

const useMessagingEnabled = () => {
    const { user } = useAuth();
    const inboxStatus = user?.currentCompany?.inbox_status;

    return useMemo(() => inboxStatus?.sms && inboxStatus?.inbox, [inboxStatus]);
};

export default useMessagingEnabled;
