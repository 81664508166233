import React, { FC, PropsWithChildren, useCallback } from 'react';
import moment, { Moment } from 'moment-timezone';

// material-ui
import { FormControl, FormHelperText, Button, CardActions, CardContent, Divider, Grid, Typography } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// project imports
import InputLabel from 'ui-component/extended/Form/InputLabel';
import { ICustomerPayload } from 'models/ICustomer';
import OptimizedTextField from '../../ui-component/optimized-text-fields/OptimizedTextField';
import CustomPhoneInput from '../../ui-component/form/CustomPhoneInput';
import AppointmentAddressBox from '../../ui-component/AppointmentAddressBox';
import { IAddress } from '../../models/IAddress';
import EmployeeIdSelect from '../../ui-component/form/EmployeeIdSelect';
import useCustomerFormValidation from './hooks/use-customer-form-validation';
import useCanSeeCustomerDetails from '../../hooks/use-can-see-customer-details';
import useExtendedFormik from '../../hooks/useExtendedFormik';

interface CustomerFullFormProps {
    customer: ICustomerPayload;
    update: (customer: ICustomerPayload) => void;
}

const addressLabels = {
    address: 'Address',
    state: 'State',
    city: 'City',
    postal_code: 'Postal code'
} as const;

const CustomerFormRow: FC<PropsWithChildren<{ label: string }>> = ({ children, label }) => (
    <>
        <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
            <InputLabel horizontal>{label}</InputLabel>
        </Grid>
        <Grid item xs={12} sm={9} lg={6}>
            {children}
        </Grid>
    </>
);

const CustomerFormDivider: FC<{ label: string }> = ({ label }) => (
    <>
        <Grid item xs={12} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
            <Typography sx={{ mt: 2 }} color="secondary" fontSize="medium" fontWeight="bold">
                {label}:
            </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
            <Divider />
        </Grid>
    </>
);

const CustomerFullForm: React.FC<CustomerFullFormProps> = ({ customer, update }) => {
    moment.tz.setDefault(moment.tz.guess());
    const { canEditCustomerOwner } = useCanSeeCustomerDetails();
    const { schema } = useCustomerFormValidation();

    const { handleSubmit, values, touched, errors, handleBlur, handleChange, setFieldValue, setFieldTouched } =
        useExtendedFormik<ICustomerPayload>({
            enableReinitialize: true,
            initialValues: customer,
            validationSchema: schema,
            onSubmit: update
        });

    const isAddressFieldTouched = useCallback(
        (field?: keyof IAddress): boolean => {
            if (field && !!touched.address && typeof touched.address === 'object') {
                return touched.address[field];
            }

            return Boolean(touched.address);
        },
        [touched.address]
    );

    const getAddressFieldError = useCallback(
        (field?: keyof IAddress) => {
            if (field && !!errors.address && typeof errors.address === 'object') {
                return errors.address[field];
            }

            return errors.address;
        },
        [errors]
    );

    return (
        <form noValidate onSubmit={handleSubmit}>
            <CardContent sx={{ px: { xs: 0, sm: 2 }, pt: { xs: 0, sm: 2 } }}>
                <Grid container spacing={2} alignItems="center">
                    <CustomerFormRow label="First Name">
                        <FormControl fullWidth>
                            <OptimizedTextField
                                id="firstname"
                                name="firstname"
                                placeholder="First Name"
                                value={values.firstname}
                                onBlur={handleBlur}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setFieldTouched('firstname', false);
                                }}
                                error={Boolean(touched.firstname && errors.firstname)}
                            />
                            {touched.firstname && errors.firstname && <FormHelperText error>{errors.firstname}</FormHelperText>}
                        </FormControl>
                    </CustomerFormRow>

                    <CustomerFormRow label="Last Name">
                        <FormControl fullWidth>
                            <OptimizedTextField
                                id="lastname"
                                name="lastname"
                                placeholder="Last Name"
                                value={values.lastname}
                                onBlur={handleBlur}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setFieldTouched('lastname', false);
                                }}
                                error={Boolean(touched.lastname && errors.lastname)}
                            />
                            {touched.lastname && errors.lastname && <FormHelperText error>{errors.lastname}</FormHelperText>}
                        </FormControl>
                    </CustomerFormRow>

                    <CustomerFormRow label="Birthday">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <FormControl fullWidth>
                                <MobileDatePicker<Moment>
                                    closeOnSelect
                                    value={values.birth_date ? moment(values.birth_date) : null}
                                    disableFuture
                                    slotProps={{
                                        toolbar: { sx: { display: 'none' } }
                                    }}
                                    minDate={moment('01-01-1900')}
                                    onChange={(date) => {
                                        if (date) {
                                            setFieldValue('birth_date', date);
                                        } else {
                                            setFieldValue('birth_date', null);
                                        }
                                    }}
                                />
                                {touched.birth_date && errors.birth_date && <FormHelperText error>{errors.birth_date}</FormHelperText>}
                            </FormControl>
                        </LocalizationProvider>
                    </CustomerFormRow>

                    <CustomerFormDivider label="Primary Contact Details" />

                    <CustomerFormRow label="Email">
                        <FormControl fullWidth>
                            <OptimizedTextField
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={values.email}
                                onBlur={handleBlur}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setFieldTouched('email', false);
                                }}
                                error={Boolean(touched.email && errors.email)}
                            />
                            {touched.email && errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
                        </FormControl>
                    </CustomerFormRow>

                    <CustomerFormRow label="Phone">
                        <CustomPhoneInput
                            id="phone"
                            name="phone"
                            placeholder="Phone"
                            value={values.phone ?? ''}
                            onChange={(v) => {
                                setFieldTouched('phone', false);
                                setFieldValue('phone', v);
                            }}
                            onBlur={handleBlur}
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone ? errors.phone : undefined}
                            fullWidth
                        />
                    </CustomerFormRow>

                    <CustomerFormDivider label="Address Details" />

                    <CustomerFormRow label="Address">
                        <FormControl fullWidth error={Boolean(isAddressFieldTouched('address') && getAddressFieldError('address'))}>
                            <AppointmentAddressBox
                                fullWidth
                                placeholder="Address"
                                id="address.address"
                                name="address.address"
                                onChange={(address: IAddress | null) => setFieldValue('address', address)}
                                onBlur={handleBlur}
                                value={values.address}
                                error={Boolean(isAddressFieldTouched('address') && getAddressFieldError('address'))}
                            />
                            {isAddressFieldTouched('address') && getAddressFieldError('address') && (
                                <FormHelperText error>{getAddressFieldError('address')}</FormHelperText>
                            )}
                        </FormControl>
                    </CustomerFormRow>

                    {(['state', 'city', 'postal_code'] as const).map((item) => (
                        <CustomerFormRow label={addressLabels[item]} key={item}>
                            <FormControl fullWidth error={Boolean(isAddressFieldTouched(item) && getAddressFieldError(item))}>
                                <OptimizedTextField
                                    id={`address.${item}`}
                                    name={`address.${item}`}
                                    placeholder={addressLabels[item]}
                                    value={values.address?.[item] || ''}
                                    onBlur={handleBlur}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handleChange(e);
                                        setFieldTouched(`address.${item}`, false);
                                    }}
                                />
                                {isAddressFieldTouched(item) && getAddressFieldError(item) && (
                                    <FormHelperText error>{getAddressFieldError(item)}</FormHelperText>
                                )}
                            </FormControl>
                        </CustomerFormRow>
                    ))}

                    <CustomerFormRow label="Note">
                        <FormControl fullWidth>
                            <OptimizedTextField
                                multiline
                                rows={4}
                                id="note"
                                name="note"
                                placeholder="Note"
                                value={values.note || ''}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {touched.note && errors.note && <FormHelperText error>{errors.note}</FormHelperText>}
                        </FormControl>
                    </CustomerFormRow>

                    {canEditCustomerOwner ? (
                        <CustomerFormRow label="Contact Owner">
                            <EmployeeIdSelect
                                value={values.employee_owner_id || null}
                                onChange={(v) => setFieldValue('employee_owner_id', v)}
                                placeholder="Contact Owner"
                                id="employee_owner_id"
                                name="employee_owner_id"
                                error={Boolean(touched.employee_owner_id && errors.employee_owner_id)}
                                helperText={touched.employee_owner_id ? errors.employee_owner_id : undefined}
                                onBlur={handleBlur}
                            />
                        </CustomerFormRow>
                    ) : null}
                </Grid>
            </CardContent>
            <Divider />
            <CardActions sx={{ px: { xs: 0, sm: 2 } }}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </form>
    );
};

export default CustomerFullForm;
