import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import CustomerTabs from 'views/customer/CustomerTabs';
import CustomerEdit from 'views/customer/CustomerEdit';
import CustomerCreate from '../views/customer/CustomerCreate';
import RoleGuard from '../utils/route-guard/RoleGuard';
import Head from '../utils/head/index';
import { WebsocketProvider } from '../contexts/WebsocketContext';
import { UserRoleProvider } from '../contexts/UserRolesContext';
// import MailboxList from '../views/mailbox/MailboxList';
// import MailboxSettings from '../views/mailbox/settings/MailboxSettings';
// import Inbox from '../views/mailbox/inbox/Inbox';
import WidgetSettings from '../views/widget-settings/WidgetSettings';
import Settings from '../views/settings/Settings';
import SettingsDefaultScreen from '../views/settings-module/SettingsDefaultScreen';
import ErrorCatcher from '../ui-component/error-catcher';
import CreateLead from '../views/create-lead';
import ProfileCardRoute from '../views/account-profile/profile/ProfileCardRoute';

const CompanyEdit = Loadable(lazy(() => import('../views/company/CompanyEdit')));
const CompanyCreate = Loadable(lazy(() => import('../views/company/CompanyCreate')));
const AccountProfile = Loadable(lazy(() => import('../views/account-profile/index')));
const VerificationMail = Loadable(lazy(() => import('../views/authentication/VerificationMail')));
const Calendar = Loadable(lazy(() => import('../views/calendar')));
const NewCalendar = Loadable(lazy(() => import('../views/new-calendar')));
const Reports = Loadable(lazy(() => import('../views/reports/Reports')));
const SearchPage = Loadable(lazy(() => import('../views/search/SearchPage')));
const CouponsPage = Loadable(lazy(() => import('../views/coupons/CouponsPage')));
const NewCouponPage = Loadable(lazy(() => import('../views/coupons/AddCoupon')));
const EditCouponPage = Loadable(lazy(() => import('../views/coupons/EditCoupon')));
const InsightsPage = Loadable(lazy(() => import('../views/insights')));
const ZoomHostPage = Loadable(lazy(() => import('../views/calls/ZoomEmployeeHostCall')));
const ZoomAppointmentHostPage = Loadable(lazy(() => import('../views/calls/ZoomAppointmentHostCall')));
const ProductsPage = Loadable(lazy(() => import('../views/products')));
const SiteBuilderPage = Loadable(lazy(() => import('../views/site-builder/SitebuilderGuard')));
// const LinkTree = Loadable(lazy(() => import('../views/link-tree')));
const SettingsModule = Loadable(lazy(() => import('../views/settings-module')));
const EmployeeScreen = Loadable(lazy(() => import('../views/employee')));
const ServicesScreen = Loadable(lazy(() => import('../views/service')));
const LocationsScreen = Loadable(lazy(() => import('../views/location')));
const WaiverScreen = Loadable(lazy(() => import('../views/waiver')));
const ReviewManagementScreen = Loadable(lazy(() => import('../views/review-management')));
const ServicesCategoriesScreen = Loadable(lazy(() => import('../views/service-categories')));
const LoyaltyProgramPage = Loadable(lazy(() => import('../views/loyalty-program')));

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <WebsocketProvider>
                <UserRoleProvider>
                    <MainLayout />
                </UserRoleProvider>
            </WebsocketProvider>
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: (
                <ErrorCatcher wrappedComponentName="Calendar">
                    <Calendar />
                </ErrorCatcher>
            )
        },
        {
            path: '/reports',
            element: (
                <>
                    <Head title="Organization Reports |" />
                    <RoleGuard action="view" subject="company">
                        <ErrorCatcher wrappedComponentName="Reports">
                            <Reports />
                        </ErrorCatcher>
                    </RoleGuard>
                </>
            )
        },
        {
            path: 'email/verify/:id',
            element: (
                <ErrorCatcher wrappedComponentName="VerificationMail">
                    <Head title="Staff |" />
                    <VerificationMail />
                </ErrorCatcher>
            )
        },
        {
            path: '/customer',
            element: (
                <ErrorCatcher>
                    <Head title="Customers |" />
                    <RoleGuard action="list" subject="customer">
                        <CustomerTabs />
                    </RoleGuard>
                </ErrorCatcher>
            )
        },
        {
            path: '/customer/:id',
            element: (
                <ErrorCatcher wrappedComponentName="CustomerEdit">
                    <Head title="Customers |" />
                    <RoleGuard action="update" subject="customer">
                        <CustomerEdit />
                    </RoleGuard>
                </ErrorCatcher>
            )
        },
        {
            path: '/customer/create/',
            element: (
                <ErrorCatcher wrappedComponentName="CustomerCreate">
                    <Head title="Customers |" />
                    <RoleGuard action="create" subject="customer">
                        <CustomerCreate />
                    </RoleGuard>
                </ErrorCatcher>
            )
        },
        // {
        //     path: '/mailbox',
        //     element: <MailboxList />
        // },
        // {
        //     path: '/mailbox/settings/:id',
        //     element: <MailboxSettings />
        // },
        // {
        //     path: '/mailbox/settings/edit/:id',
        //     element: <MailboxSettings />
        // },
        // {
        //     path: '/mailbox/settings/connection/:id',
        //     element: <MailboxSettings />
        // },
        // {
        //     path: '/mailbox/settings/permissions/:id',
        //     element: <MailboxSettings />
        // },
        // {
        //     path: '/mailbox/create/:email/:id',
        //     element: <MailboxSettings />
        // },
        // {
        //     path: '/mailbox/create/:email',
        //     element: <MailboxSettings />
        // },
        // {
        //     path: '/mailbox/:id',
        //     element: <Inbox />
        // },
        // {
        //     path: '/mailbox/:id/conversations/:id/threads',
        //     element: <Inbox />
        // },
        {
            path: '/calendar/legacy',
            element: (
                <ErrorCatcher wrappedComponentName="Calendar">
                    <Head title="Calendar |" />
                    <Calendar />
                </ErrorCatcher>
            )
        },
        {
            path: '/calendar',
            element: (
                <ErrorCatcher wrappedComponentName="NewCalendar">
                    <Head title="Calendar |" />
                    <NewCalendar />
                </ErrorCatcher>
            )
        },
        {
            path: '/profile/*',
            element: (
                <ErrorCatcher wrappedComponentName="AccountProfile">
                    <Head title="Profile Settings |" />
                    <AccountProfile />
                </ErrorCatcher>
            )
        },
        {
            path: '/profile/cbvc',
            element: (
                <ErrorCatcher wrappedComponentName="ZoomHostPage">
                    <Head title="Starting Call |" />
                    <ZoomHostPage />
                </ErrorCatcher>
            )
        },
        {
            path: '/profile-card-link/*',
            element: (
                <ErrorCatcher wrappedComponentName="ProfileCardRoute">
                    <Head title="Profile Links |" />
                    <ProfileCardRoute />
                </ErrorCatcher>
            )
        },
        {
            path: '/appointment/:appointmentId/cbvc',
            element: (
                <ErrorCatcher wrappedComponentName="ZoomAppointmentHostPage">
                    <Head title="Starting Call |" />
                    <ZoomAppointmentHostPage />
                </ErrorCatcher>
            )
        },
        {
            path: '/search',
            element: (
                <ErrorCatcher wrappedComponentName="SearchPage">
                    <Head title="Search |" />
                    <SearchPage />
                </ErrorCatcher>
            )
        },

        {
            path: '/coupons',
            element: (
                <ErrorCatcher wrappedComponentName="CouponsPage">
                    <Head title="Coupons |" />
                    <CouponsPage />
                </ErrorCatcher>
            )
        },
        {
            path: '/coupons/:id',
            element: (
                <ErrorCatcher wrappedComponentName="EditCouponPage">
                    <Head title="Edit Coupon |" />
                    <EditCouponPage />
                </ErrorCatcher>
            )
        },
        {
            path: '/coupons/new',
            element: (
                <ErrorCatcher wrappedComponentName="NewCouponPage">
                    <Head title="New Coupon |" />
                    <NewCouponPage />
                </ErrorCatcher>
            )
        },
        {
            path: '/insights',
            element: (
                <ErrorCatcher wrappedComponentName="InsightsPage">
                    <Head title="Insights |" />
                    <InsightsPage />
                </ErrorCatcher>
            )
        },
        {
            path: '/site-builder',
            element: (
                <RoleGuard action="update" subject="company">
                    <ErrorCatcher wrappedComponentName="SiteBuilderPage">
                        <Head title="Site Builder |" />
                        <SiteBuilderPage />
                    </ErrorCatcher>
                </RoleGuard>
            )
        },
        // {
        //     path: '/profile-card',
        //     element: (
        //         <ErrorCatcher wrappedComponentName="LinkTree">
        //             <Head title="Profile Card |" />
        //             <LinkTree />
        //         </ErrorCatcher>
        //     )
        // },
        {
            path: '/organization/create',
            element: (
                <>
                    <Head title="Organization |" />
                    <RoleGuard action="create" subject="company">
                        <CompanyCreate />
                    </RoleGuard>
                </>
            )
        },
        {
            path: '/review-management/*',
            element: (
                <ErrorCatcher wrappedComponentName="ReviewManagementScreen">
                    <Head title="Review Management |" />
                    <RoleGuard action="update" subject="location">
                        <ReviewManagementScreen />
                    </RoleGuard>
                </ErrorCatcher>
            )
        },
        {
            path: '/settings',
            element: <SettingsModule />,
            children: [
                {
                    path: '',
                    element: <SettingsDefaultScreen />
                },
                {
                    path: '/settings/preferences',
                    element: (
                        <>
                            <Head title="Organization Settings |" />
                            <RoleGuard action="update" subject="company">
                                <Settings />
                            </RoleGuard>
                        </>
                    )
                },
                {
                    path: '/settings/employee/*',
                    element: (
                        <>
                            <Head title="Staff |" />
                            <RoleGuard action="list" subject="employee">
                                <EmployeeScreen />
                            </RoleGuard>
                        </>
                    )
                },
                {
                    path: '/settings/service/*',
                    element: (
                        <>
                            <Head title="Services |" />
                            <RoleGuard action="list" subject="service">
                                <ServicesScreen />
                            </RoleGuard>
                        </>
                    )
                },
                {
                    path: '/settings/service-categories',
                    element: (
                        <>
                            <Head title="Service Categories |" />
                            <RoleGuard action="list" subject="service">
                                <ServicesCategoriesScreen />
                            </RoleGuard>
                        </>
                    )
                },
                {
                    path: '/settings/organization',
                    element: (
                        <>
                            <Head title="Organization |" />
                            <RoleGuard action="update" subject="company">
                                <CompanyEdit />
                            </RoleGuard>
                        </>
                    )
                },
                {
                    path: '/settings/location/*',
                    element: (
                        <>
                            <Head title="Locations |" />
                            <RoleGuard action="list" subject="location">
                                <LocationsScreen />
                            </RoleGuard>
                        </>
                    )
                },
                {
                    path: '/settings/widget-settings',
                    element: (
                        <>
                            <Head title="Booking Widget |" />
                            <RoleGuard action="update" subject="company">
                                <WidgetSettings />
                            </RoleGuard>
                        </>
                    )
                },
                {
                    path: '/settings/forms/*',
                    element: (
                        <>
                            <Head title="Form Builder |" />
                            <RoleGuard action="update" subject="company">
                                <WaiverScreen />
                            </RoleGuard>
                        </>
                    )
                },
                {
                    path: '/settings/products/*',
                    element: (
                        <>
                            <Head title="Products | " />
                            <RoleGuard action="list" subject="product">
                                <ProductsPage />
                            </RoleGuard>
                        </>
                    )
                },
                {
                    path: '/settings/loyalty-program',
                    element: (
                        <>
                            <Head title="Loyalty Program | " />
                            <RoleGuard action="update" subject="company">
                                <LoyaltyProgramPage />
                            </RoleGuard>
                        </>
                    )
                }
            ]
        },
        {
            path: '/create-lead',
            element: (
                <ErrorCatcher wrappedComponentName="CreateLead">
                    <Head title="Create Lead | " />
                    <CreateLead />
                </ErrorCatcher>
            )
        }
    ]
};

export default MainRoutes;
