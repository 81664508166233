import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Menu from './elements/Menu';
import AppointmentForm from '../../../appointment-form';
import Activity from './elements/tabs-content/Activity';
import AppointmentMessages from './elements/tabs-content/AppointmentMessages';
import CustomerInfo from './elements/tabs-content/customer-info';
import Payments from './elements/tabs-content/payments';
import { Alert, Box, Button, CircularProgress, Link, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { setShouldSubmitFormOnTabChange } from '../../../../store/slices/calendarSlice';
import { useSearchParams } from 'react-router-dom';
import AppointmentProducts from './elements/tabs-content/products/AppointmentProducts';
import { openConfirmPopup } from '../../../../store/confirmPopupSlice';
import EntityDrawerActions from '../../../entity-drawer-layout/EntityDrawerActions';
import EntityDrawerContent from '../../../entity-drawer-layout/EntityDrawerContent';
import { useAppointmentFunctions } from '../../../../hooks/appointments';
import CompleteAppointmentBtn from '../../../complete-appointment-btn';
import { AppointmentStatuses } from '../../../../models/IAppointment';
import moment from 'moment/moment';

interface AppointmentDetailsProps {
    isLoading?: boolean;
    onClose?: () => void;
}

const tabsWithCommonActions = ['customer-info', 'details', 'payments'];

const AppointmentDetails = ({ isLoading, onClose }: AppointmentDetailsProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<string>('details');
    const {
        appointmentDetailsFormId,
        appointmentDetailsSaveButtonDisabled,
        selectedEvent,
        shouldSubmitFormOnTabChange,
        isForeignAppointment
    } = useAppSelector((state) => state.calendar);
    const { selectedLocation } = useAppSelector((state) => state.calendarFilter);
    const dispatch = useAppDispatch();
    const { appointmentToFormData } = useAppointmentFunctions();

    const submitButtonRef = useRef<null | HTMLButtonElement>(null);

    const content = useMemo(() => {
        switch (activeTab) {
            case 'customer-info':
                return <CustomerInfo onResetTabs={() => setActiveTab('details')} />;
            case 'activity':
                return <Activity />;
            case 'messages':
                return <AppointmentMessages />;
            case 'products':
                return <AppointmentProducts />;
            case 'payments':
                return <Payments />;
            case 'details':
            default:
                return (
                    <>
                        {selectedEvent && <AppointmentForm data={appointmentToFormData(selectedEvent)} isReadOnly={isForeignAppointment} />}
                    </>
                );
        }
    }, [activeTab, appointmentToFormData, isForeignAppointment, selectedEvent]);

    const showCompleteButton = useMemo(() => {
        if (selectedEvent) {
            const appointmentEnd = moment(selectedEvent.end_at);
            const isAfter = moment().isAfter(appointmentEnd);
            return selectedEvent.status === AppointmentStatuses.Active && isAfter;
        }
        return false;
    }, [selectedEvent]);

    const showCommonActions = useMemo(() => {
        const tabHasActions = tabsWithCommonActions.includes(activeTab);
        return tabHasActions && !!appointmentDetailsFormId;
    }, [activeTab, appointmentDetailsFormId]);

    const handleClickTab = useCallback(
        (newValue: string) => {
            if (shouldSubmitFormOnTabChange && !isForeignAppointment) {
                dispatch(
                    openConfirmPopup({
                        text: 'Save changes before switching tab?',
                        confirmText: 'Save Changes',
                        cancelText: 'Discard Changes',
                        onConfirm: () => {
                            submitButtonRef?.current?.click();
                            dispatch(setShouldSubmitFormOnTabChange(false));
                            setActiveTab(newValue);
                        },
                        onClose: () => {
                            setActiveTab(newValue);
                            dispatch(setShouldSubmitFormOnTabChange(false));
                        }
                    })
                );
            } else {
                setActiveTab(newValue);
            }
        },
        [shouldSubmitFormOnTabChange, isForeignAppointment, dispatch]
    );

    useEffect(() => {
        const tab = searchParams.get('tab');
        if (tab === 'messages') {
            setActiveTab('messages');
            setSearchParams(new URLSearchParams());
        }
    }, [searchParams, setSearchParams]);

    return (
        <>
            <EntityDrawerContent noSpacing>
                <Stack height="100%" alignContent="flex-start">
                    <Menu
                        activeTab={activeTab}
                        handleClickTab={handleClickTab}
                        has_waiver={selectedEvent?.has_waiver}
                        deposit_request={selectedEvent?.deposit_request}
                        disabled={isLoading}
                    />

                    <Box
                        py={2}
                        height="calc(100% - 48px)"
                        overflow="auto"
                        sx={{
                            '&::-webkit-scrollbar': {
                                height: '5px',
                                width: '5px'
                            },

                            '&::-webkit-scrollbar-track': {
                                borderRadius: '3px',
                                backgroundColor: 'grey.200'
                            },

                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'grey.400',
                                borderRadius: '3px'
                            }
                        }}
                    >
                        {!isLoading && !isForeignAppointment && selectedLocation && selectedEvent?.location.id !== selectedLocation.id ? (
                            <Box px={2} mb={1}>
                                <Alert severity="info">This appointment is for {selectedEvent?.location.name}</Alert>
                            </Box>
                        ) : null}
                        {!isLoading && isForeignAppointment && !!selectedEvent?.foreign_company ? (
                            <Box px={2} mb={1}>
                                <Alert severity="info">
                                    This appointment is for{' '}
                                    <Link
                                        href={`/calendar/organization/${selectedEvent?.foreign_company.id}/appointment/${selectedEvent?.id}/`}
                                    >
                                        <>{selectedEvent?.foreign_company.name}</>
                                    </Link>
                                </Alert>
                            </Box>
                        ) : null}
                        {!isLoading ? (
                            content
                        ) : (
                            <Box
                                sx={{
                                    p: 3,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )}
                    </Box>
                </Stack>
            </EntityDrawerContent>

            {/* Actions */}
            <EntityDrawerActions id="appointment-details-actions">
                {showCommonActions ? (
                    <>
                        {selectedEvent && showCompleteButton ? <CompleteAppointmentBtn appointmentId={selectedEvent.id} /> : null}

                        {onClose && (
                            <Button onClick={onClose} color="primary" variant="text" className="DialogCancelBtn forcedBg">
                                Close
                            </Button>
                        )}

                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={
                                appointmentDetailsSaveButtonDisabled || isForeignAppointment || !shouldSubmitFormOnTabChange || isLoading
                            }
                            form={appointmentDetailsFormId}
                            className="DialogOkBtn"
                            ref={submitButtonRef}
                        >
                            Save
                        </Button>
                    </>
                ) : null}
            </EntityDrawerActions>
        </>
    );
};

export default AppointmentDetails;
