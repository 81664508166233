import { FC, useMemo, useState } from 'react';
import { IAppointmentHistory } from '../../../../models/IAppointment';
import useAuth from '../../../../hooks/useAuth';
import moment from 'moment-timezone';
import { Box, Collapse, Link } from '@mui/material';
import BlockTimeHistoryField from './BlockTimeHistoryField';

const fieldTypes = ['start_at', 'end_at', 'employee_id', 'note'];

const BlockTimeHistoryRecord: FC<{ item: IAppointmentHistory }> = ({ item }) => {
    const [open, setOpen] = useState(false);
    const { user } = useAuth();

    const currentTimezone = moment.tz.guess();
    const currentUserId = user?.id;

    const username = useMemo(() => {
        if (item.user) {
            return currentUserId === item.user.id ? 'You' : `${item.user.title}`;
        }

        return 'Unknown';
    }, [item.user, currentUserId]);

    const changeTime = moment(item.created_at).tz(currentTimezone).format('hh:mm a, MM/DD/YYYY');

    const entries = useMemo(
        () => Object.entries(item.modified_values).filter((entry) => fieldTypes.includes(entry[0])),
        [item.modified_values]
    );

    return (
        <Box>
            <Box>
                {`${username} ${item?.event} block time at ${changeTime} via ${item.event_source}`}{' '}
                {entries.length > 1 ? <Link onClick={() => setOpen((prev) => !prev)}>Details</Link> : null}
            </Box>
            <Collapse in={entries.length === 1 ? true : open}>
                <Box component="ul" margin={0}>
                    {entries.map(([key, value]) => (
                        <BlockTimeHistoryField
                            key={key}
                            label={key}
                            newValue={String(value.new)}
                            oldValue={value.old ? String(value.old) : undefined}
                        />
                    ))}
                </Box>
            </Collapse>
        </Box>
    );
};

export default BlockTimeHistoryRecord;
