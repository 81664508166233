import { APP_PHONE_FORMAT } from '../store/constant';
import NumberFormat from 'react-number-format';
import { useTheme } from '@material-ui/core/styles';
import { SxProps } from '@mui/system';
import { Box } from '@mui/material';

interface FormattedPhoneNumberProps {
    phone: string;
    sx?: SxProps;
}

const FormattedPhoneNumber = ({ phone, sx }: FormattedPhoneNumberProps) => {
    const theme = useTheme();

    return (
        <Box
            component="span"
            sx={{
                color: `${theme.palette.text.dark}`,
                '& .FormattedPhone': {
                    color: 'inherit',
                    fontWeight: 'inherit',
                    fontSize: 'inherit'
                },
                ...sx
            }}
        >
            <NumberFormat value={phone} type="tel" displayType="text" format={APP_PHONE_FORMAT} className="FormattedPhone" />
        </Box>
    );
};

export default FormattedPhoneNumber;
