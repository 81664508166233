import React from 'react';
import { Menu, MenuItem, Pagination, Button, Stack, Box, Typography } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

export interface IPagePagination {
    data: any;
    perPage: number;
    setPageSize?: (limit?: number) => void;
    page: number;
    setPage: (arg: number) => void;
    additionalContent?: React.ReactNode | string;
}

const AppPagePagination = ({ data, perPage, setPageSize, setPage, page, additionalContent }: IPagePagination) => {
    const [anchorEl, setAnchorEl] = React.useState<Element | null | undefined>(null);

    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePageSizeChange = (size?: number) => {
        setPageSize?.(size);
        setAnchorEl(null);
    };

    return (
        <Stack py={2} spacing={{ xs: 1, sm: 3 }} direction="row" alignItems="center" justifyContent="space-between">
            <Pagination
                page={page}
                onChange={(event, selectedPage) => setPage(selectedPage)}
                count={data?.meta?.last_page}
                color="primary"
                shape="rounded"
            />

            {additionalContent && <Box>{additionalContent}</Box>}

            {setPageSize ? (
                <Stack spacing={1} direction="row" alignItems="center">
                    <Typography sx={{ color: 'grey.900', opacity: 0.6 }}>Show Results</Typography>
                    <Button
                        variant="outlined"
                        sx={{ color: 'grey.900', borderColor: 'grey.200' }}
                        endIcon={<ExpandMoreRoundedIcon />}
                        onClick={handleClick}
                    >
                        {perPage}
                    </Button>
                    <Menu
                        id="menu-user-list-style1"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        variant="selectedMenu"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                    >
                        {[10, 25, 100].map((item) => (
                            <MenuItem key={item} onClick={() => handlePageSizeChange(item)}>
                                {item}
                            </MenuItem>
                        ))}
                    </Menu>
                </Stack>
            ) : null}
        </Stack>
    );
};

export default AppPagePagination;

export class PagePagination {}
