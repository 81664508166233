import { INotification, NotificationType } from '../models/INotification';
import { useCallback, useMemo } from 'react';
import { axiosServices } from '../utils/axios';
import { setOuterAppointment } from '../store/slices/outerAppointmentSlice';
import { openConfirmPopup } from '../store/confirmPopupSlice';
import { useAppDispatch } from './redux';
import { useNavigate } from 'react-router-dom';
import notificationAPI from '../services/NotificationService';

import MessageIcon from '@mui/icons-material/Message';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PersonIcon from '@mui/icons-material/Person';
import CancelIcon from '@mui/icons-material/Cancel';
import { DialogTypes, openDialog } from '../store/slices/entityDialogsSlice';

const notificationsVariants = {
    [NotificationType.appointmentCanceled]: {
        title: `Appointment Cancelled`,
        icon: <CancelIcon />
    },
    [NotificationType.appointmentDateUpdated]: {
        title: `Appointment Rescheduled`,
        icon: <EventNoteIcon />
    },
    [NotificationType.appointmentCreated]: {
        title: `Appointment Created`,
        icon: <EventNoteIcon />
    },
    [NotificationType.appointmentCompleted]: {
        title: `Appointment Completed`,
        icon: <EventNoteIcon />
    },
    [NotificationType.appointmentCheckedIn]: {
        title: `Customer Arrived`,
        icon: <PersonIcon />
    },
    [NotificationType.incomingMessage]: {
        title: `New Message`,
        icon: <MessageIcon />
    }
} as const;

const useNotificationItem = (notification: INotification | null) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [readNotification] = notificationAPI.useMarkNotificationAsReadMutation();

    const notificationCustomer = notification?.data.customer;
    const customerName = useMemo(() => {
        if (notificationCustomer) {
            const { firstname, lastname } = notificationCustomer;
            return [firstname, lastname].filter((s) => !!s).join(' ');
        }

        return '';
    }, [notificationCustomer]);

    const notificationType = notification?.type;
    const notificationTitle = notification?.title ?? '';
    const notificationData = useMemo(
        () =>
            notificationType && notificationsVariants[notificationType]
                ? notificationsVariants[notificationType]
                : {
                      title: notificationTitle,
                      icon: null
                  },
        [notificationType, notificationTitle]
    );

    const isMessage = useMemo(() => notificationType === NotificationType.incomingMessage, [notificationType]);

    const dataId = notification?.data.id;
    const closestUpcomingAppointment = notification?.data?.closest_upcoming_appointment?.id;
    const notificationId = notification?.id;
    const customerId = notification?.data?.customer?.id;

    const handleClickNotification = useCallback(async () => {
        const entityId = closestUpcomingAppointment ?? dataId;
        if (entityId && notificationId) {
            try {
                const res = await axiosServices.get(`/appointments/${entityId}`);
                if (res.data) {
                    dispatch(setOuterAppointment(res.data));
                    navigate(isMessage ? '/calendar?tab=messages' : '/calendar', { replace: true });
                }
                readNotification(notificationId);
            } catch (err) {
                if (err.message === 'Record not found.') {
                    dispatch(
                        openConfirmPopup({
                            text: 'This appointment was deleted and can`t be opened'
                        })
                    );
                    readNotification(notificationId);
                } else {
                    dispatch(
                        openConfirmPopup({
                            confirmText: `Ok`,
                            text: 'An error occurred. Please try again later'
                        })
                    );
                }
            }
        }

        if (!entityId && isMessage && customerId) {
            notificationId && readNotification(notificationId);
            navigate('?tab=messages');
            dispatch(openDialog({ id: customerId, type: DialogTypes.Customer }));
        }
    }, [dataId, dispatch, isMessage, navigate, notificationId, readNotification, closestUpcomingAppointment, customerId]);

    return { customerName, notificationData, isMessage, handleClickNotification };
};

export default useNotificationItem;
