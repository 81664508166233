import { FC } from 'react';
import customerAPI from '../../../../services/CustomerService';
import { Box } from '@mui/material';
import LoadingSpinner from '../../../../ui-component/LoadingSpinner';
import MessagesComponent from '../../../../ui-component/MessagesComponent';
import EntityDrawerContent from '../../../../ui-component/entity-drawer-layout/EntityDrawerContent';

const CustomerMessages: FC<{ customerId: string }> = ({ customerId }) => {
    const { data, isLoading } = customerAPI.useGetCustomerQuery(customerId, {
        refetchOnMountOrArgChange: true
    });

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (!data) {
        return null;
    }

    return (
        <EntityDrawerContent noSpacing>
            <Box py={2} sx={{ height: '100%', width: '100%' }}>
                <MessagesComponent customer={data} />
            </Box>
        </EntityDrawerContent>
    );
};

export default CustomerMessages;
