import { useCallback, useMemo, useState } from 'react';
import { ICompany, IWidgetLinkBuilder } from '../../models/ICompany';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    FormHelperText,
    useMediaQuery,
    Theme,
    Stack,
    SelectChangeEvent
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import useAuth from '../../hooks/useAuth';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import useGenerateLink from './useGenerateLink';
import { getNewMultiServicesValue } from '../../utils/services';
import LinkBuilderItem from './components/widget-link-builder/LinkBuilderItem';
import ErrorCatcher from '../../ui-component/error-catcher';
import WidgetLinkForm from './components/widget-link-builder/WidgetLinkForm';

interface WidgetLinkBuilderProps {
    links?: IWidgetLinkBuilder[];
    setFieldValue: (fieldName: string, value: any) => void;
    company: ICompany;
    errors?: string | Array<Record<string, string> | null> | null;
}

const commonCellSx = {
    pl: 0,
    py: 1,
    pr: 2
};

const WidgetLinkBuilder = ({ links, setFieldValue, company, errors }: WidgetLinkBuilderProps) => {
    const isMobile = useMediaQuery((themeParams: Theme) => themeParams.breakpoints.down('mobile'));
    const { user } = useAuth();
    const { showSnackbar } = useShowSnackbar();
    const { generateLink } = useGenerateLink(company);
    const [editedIndex, setEditedIndex] = useState<number | null>(null);

    const editingLink = useMemo<IWidgetLinkBuilder | null | undefined>(
        () => (editedIndex !== null ? links?.[editedIndex] : null),
        [editedIndex, links]
    );

    const handleDelete = useCallback(
        (index: number) => {
            const values = links?.length ? [...links] : [];
            values?.splice(index, 1);
            setFieldValue('link_builder', values || undefined);
        },
        [links, setFieldValue]
    );

    const addRow = useCallback(() => {
        const emptyRow = { location_id: null, service_id: [], employee_id: null, selector: '' };
        const value = links?.length ? [...links, emptyRow] : [emptyRow];
        setFieldValue('link_builder', value);
    }, [links, setFieldValue]);

    const copyLink = useCallback(
        (index) => {
            let url = `${window.location.origin}/cal/${user?.currentCompany.slug}`;
            if (links?.length) {
                url += generateLink(links[index]);
            }

            navigator.clipboard.writeText(url).then(() => {
                showSnackbar({
                    message: 'Copied!',
                    alertSeverity: SnackBarTypes.Success
                });
            });
        },
        [links, user, showSnackbar, generateLink]
    );

    const getErrorByIndex = useCallback(
        (index: number) => {
            if (typeof errors === 'string') {
                return null;
            }

            return errors?.[index]?.selector;
        },
        [errors]
    );

    const handleChangeServiceValue = useCallback(
        (index: number, event: SelectChangeEvent<number[]>) => {
            if (typeof event.target.value !== 'string') {
                const key = `link_builder[${index}].service_ids`;
                const value = getNewMultiServicesValue(event.target.value, company.services ?? []);
                setFieldValue(key, value);
            }
        },
        [company.services, setFieldValue]
    );

    const getWidgetItemData = useCallback(
        (item: IWidgetLinkBuilder) => {
            const services =
                item.service_ids
                    ?.map((service_id) => company.services?.find(({ id }) => id === service_id)?.name ?? null)
                    .filter((s) => !!s) ?? [];
            const locationData = {
                label: 'Location',
                value: company.locations?.find(({ id }) => id === item.location_id)?.name ?? '-'
            };

            const staffData = {
                label: 'Staff',
                value: company.employees?.find(({ id }) => id === item.employee_id)?.user.title ?? '-'
            };

            const serviceData = {
                label: 'Services',
                value: services.length ? services.join(', ') : '-'
            };

            const selector = {
                label: 'Selector',
                value: item.selector ?? '-'
            };

            return [locationData, staffData, serviceData, selector];
        },
        [company.employees, company.locations, company.services]
    );

    const onEditLink = useCallback((index: number) => {
        setEditedIndex(index);
    }, []);

    const saveItem = useCallback(
        (data: IWidgetLinkBuilder) => {
            if (editedIndex !== null) {
                setFieldValue(`link_builder[${editedIndex}]`, data);
                setEditedIndex(null);
            }
        },
        [editedIndex, setFieldValue]
    );

    return (
        <>
            <FormHelperText sx={{ m: 0, fontSize: '0.875rem', width: '600px', maxWidth: '100%' }}>
                Use link builder to create booking widget links that target specific locations, services, providers, or any combinations of
                the three. Share this links directly with select customers when needed. Private services are only available for booking
                using service specific link.
            </FormHelperText>
            {isMobile ? (
                <Stack spacing={2} mt={1} mb={2}>
                    {links?.map((link, index) => (
                        <ErrorCatcher wrappedComponentName="LinkBuilderItem" key={index}>
                            <LinkBuilderItem
                                index={index}
                                onEdit={() => onEditLink(index)}
                                onCopy={() => copyLink(index)}
                                onDelete={() => handleDelete(index)}
                                data={getWidgetItemData(link)}
                            />
                        </ErrorCatcher>
                    ))}
                </Stack>
            ) : (
                <Box mt={1} mb={2} overflow="auto">
                    <Table>
                        <TableBody>
                            {links?.map((link, index) => (
                                <TableRow key={`link_builder_${index}`}>
                                    <TableCell width={200} sx={commonCellSx}>
                                        <TextField
                                            select
                                            fullWidth
                                            label="Location"
                                            value={link.location_id || ''}
                                            onChange={(event) => {
                                                setFieldValue(`link_builder[${index}].location_id`, event.target.value);
                                            }}
                                        >
                                            <MenuItem value="">
                                                <i>None</i>
                                            </MenuItem>
                                            {company.locations?.map((location) => (
                                                <MenuItem key={`location_${index}_${location.id}`} value={location.id}>
                                                    {location.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </TableCell>
                                    <TableCell width={200} sx={commonCellSx}>
                                        <FormControl fullWidth variant="filled">
                                            <InputLabel id={`service_label_${index}`}>Service</InputLabel>
                                            <Select
                                                variant="filled"
                                                labelId={`service_label_${index}`}
                                                label="Service"
                                                value={link.service_ids || []}
                                                multiple
                                                onChange={(event) => {
                                                    handleChangeServiceValue(index, event);
                                                }}
                                            >
                                                {company.services?.map((service) => (
                                                    <MenuItem key={`service_${index}_${service.id}`} value={service.id}>
                                                        {service.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell width={200} sx={commonCellSx}>
                                        <TextField
                                            fullWidth
                                            select
                                            label="Staff"
                                            value={link.employee_id || ''}
                                            onChange={(event) => {
                                                setFieldValue(`link_builder[${index}].employee_id`, event.target.value);
                                            }}
                                        >
                                            <MenuItem value="">
                                                <i>None</i>
                                            </MenuItem>
                                            {company.employees?.map((employee) => (
                                                <MenuItem key={`employee_${index}_${employee.id}`} value={employee.id}>
                                                    {employee.user.title}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </TableCell>
                                    <TableCell width={200} sx={commonCellSx}>
                                        <TextField
                                            fullWidth
                                            label="Selector"
                                            value={link.selector ?? ''}
                                            id={`link_builder[${index}].selector`}
                                            name={`link_builder[${index}].selector`}
                                            onChange={(e) => setFieldValue(`link_builder[${index}].selector`, e.target.value)}
                                            error={Boolean(getErrorByIndex(index))}
                                            helperText={getErrorByIndex(index)}
                                        />
                                    </TableCell>
                                    <TableCell width={80} sx={{ px: 0, py: 1 }}>
                                        <Box component="span" sx={{ color: 'primary.900' }}>
                                            <IconButton color="inherit" onClick={() => copyLink(index)}>
                                                <FileCopyOutlinedIcon />
                                            </IconButton>
                                        </Box>
                                        <IconButton color="error" onClick={() => handleDelete(index)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}

            <Button endIcon={<Add />} onClick={addRow} className="forcedBg">
                Add
            </Button>

            {isMobile ? (
                <WidgetLinkForm
                    open={editedIndex !== null}
                    onClose={() => setEditedIndex(null)}
                    defaults={editingLink}
                    company={company}
                    onSubmit={saveItem}
                />
            ) : null}
        </>
    );
};

export default WidgetLinkBuilder;
