import { Fragment } from 'react';
// material-ui
import { makeStyles } from '@material-ui/styles';
import { Divider, List, ListItemButton, ListItemText, Typography } from '@material-ui/core';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { format } from 'date-fns';

// assets
import { Stack } from '@mui/material';
import { IConversation } from '../../models/IAppointment';

// style constant
const useStyles = makeStyles(() => ({
    ScrollHeight: {
        flexGrow: 1,
        overflowX: 'hidden',

        '& .MuiList-root': {
            paddingTop: 0
        }
    }
}));

// ==============================|| CHAT DRAWER ||============================== //

export interface ChatDrawerProps {
    conversations: IConversation[];
    onSelectConversation: (id: number) => void;
    selectedId: number | null;
}

const ChatDrawer = ({ conversations, onSelectConversation, selectedId }: ChatDrawerProps) => {
    const classes = useStyles();

    return (
        <Stack spacing={1} sx={{ width: '50%', maxWidth: '250px', flexShrink: 0, flexGrow: 0 }}>
            {/* <Stack spacing={1} sx={{ px: 1 }}> */}
            {/*    <Stack spacing={1} alignItems="center" direction="row"> */}
            {/*        <UserAvatar user={{ avatar: '', name: 'User 1' }} /> */}
            {/*        <Typography align="left" variant="h4"> */}
            {/*            John Doe */}
            {/*        </Typography> */}
            {/*    </Stack> */}
            {/*    <OutlinedInput */}
            {/*        fullWidth */}
            {/*        id="input-search-header" */}
            {/*        placeholder="Search Mail" */}
            {/*        startAdornment={ */}
            {/*            <InputAdornment position="start"> */}
            {/*                <SearchTwoToneIcon fontSize="small" /> */}
            {/*            </InputAdornment> */}
            {/*        } */}
            {/*    /> */}
            {/* </Stack> */}
            <PerfectScrollbar className={classes.ScrollHeight}>
                <List component="nav">
                    {conversations.map((conversation) => (
                        <Fragment key={conversation.id}>
                            <ListItemButton onClick={() => onSelectConversation(conversation.id)} selected={selectedId === conversation.id}>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="h5"
                                            color="inherit"
                                            component="span"
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                display: 'block'
                                            }}
                                        >
                                            {[conversation.customer?.firstname, conversation.customer?.lastname]
                                                .filter((el) => !!el)
                                                .join(' ')}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            variant="caption"
                                            component="span"
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                display: 'block'
                                            }}
                                        >
                                            {`${conversation.preview}, ${format(
                                                new Date(conversation.customer_waiting_since.time),
                                                'h:mm a, MM/dd/yyyy'
                                            )}`}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                            <Divider />
                        </Fragment>
                    ))}
                </List>
            </PerfectScrollbar>
        </Stack>
    );
};

export default ChatDrawer;
