import { Box, Typography } from '@mui/material';
import WidgetServicePolicy from '../../../../../ui-component/widgets/widget-service-policy';
import AuthorizeNetForm from './components/AuthorizeNetForm';
import StripePayment from './components/stripe/StripePayment';
import PaypalForm from './components/PaypalForm';
import * as DOMPurify from 'dompurify';
import useDepositFunctions from '../../../hooks/useDepositFunctions';
import { useAppSelector } from '../../../../../hooks/redux';
import useAcceptServicePolicies from '../../../../../hooks/use-accept-service-policies';
import { FC } from 'react';
import { UploadableFile } from '../../../../../ui-component/file-uploader-preview/AttachmentsUpload';
import { IService } from '../../../../../models/IService';
import { ILocation } from '../../../../../models/ILocation';
import { PaymentGateways } from '../../../../../models/ICompany';
import { IWidgetUser, TimeSlot } from '../../types';
import { IEmployee } from '../../../../../models/IEmployee';
import SquareUpForm from './components/square_up';

interface IWidgetPaymentFormProps {
    services: IService[];
    attachments: UploadableFile[];
    location: Pick<ILocation, 'id'> | null;
    gateways?: PaymentGateways | undefined;
    depositText?: string;
    date: TimeSlot | null;
    provider: IEmployee | null;
    timezone: string;
    user: IWidgetUser | null;
    submitBooking: (paymentDetails?: object | null, user?: IWidgetUser) => void;
    company_slug?: string;
}

const WidgetPaymentForm: FC<IWidgetPaymentFormProps> = ({
    services,
    location,
    attachments,
    depositText,
    gateways,
    date,
    provider,
    timezone,
    user,
    submitBooking,
    company_slug
}) => {
    const { servicePrice } = useDepositFunctions();
    const { coupon } = useAppSelector((store) => store.widget);
    const { accepted, servicesWithPolicy, onToggleAccept, allServicesAccepted } = useAcceptServicePolicies(services);

    return (
        <>
            <Box mb={1}>
                {servicesWithPolicy.map(({ id, use_service_policy, name }, index) => (
                    <WidgetServicePolicy
                        key={id}
                        accepted={accepted.includes(id)}
                        setAccepted={(v: boolean) => onToggleAccept(v, id)}
                        name={use_service_policy.policy_title ?? ''}
                        serviceName={name}
                        text={use_service_policy.policy_text ?? ''}
                    />
                ))}
            </Box>

            {servicePrice && services ? (
                <>
                    {!gateways?.paypal?.client_id &&
                        !gateways?.authorize_net?.is_enabled &&
                        !gateways?.stripe?.is_enabled &&
                        !gateways?.square_up?.is_enabled && (
                            <Typography>Invalid payment gateway configuration, please contact support.</Typography>
                        )}

                    {gateways?.authorize_net?.is_enabled && (
                        <AuthorizeNetForm
                            disabled={!allServicesAccepted}
                            submitBooking={submitBooking}
                            fee={gateways?.authorize_net?.service_fee_amount}
                        />
                    )}

                    {gateways?.stripe?.is_enabled && gateways?.stripe?.publishable_key && (
                        <StripePayment
                            disabled={!allServicesAccepted}
                            services={services}
                            date={date}
                            submitBooking={submitBooking}
                            stripePublicKey={gateways.stripe.publishable_key}
                            entity={{
                                service_ids: services.map(({ id }) => id)
                            }}
                            savedData={{
                                service_ids: services.map(({ id }) => id),
                                location_id: location?.id,
                                user: user || undefined,
                                employee_id: provider?.id,
                                images: attachments.map((value) => value.url || ''),
                                start_at: date?.start_at,
                                end_at: date?.end_at,
                                time_zone: timezone,
                                coupon_code: coupon?.code
                            }}
                            companySlug={company_slug}
                        />
                    )}

                    {gateways?.square_up?.is_enabled && gateways?.square_up?.application_id && gateways?.square_up?.location_id ? (
                        <SquareUpForm
                            application_id={gateways.square_up.application_id}
                            location_id={gateways.square_up.location_id}
                            onTokenReceive={(token) =>
                                submitBooking(
                                    {
                                        gateway: 'square_up',
                                        token
                                    },
                                    user ?? undefined
                                )
                            }
                        />
                    ) : null}

                    {gateways?.paypal?.client_id && services && (
                        <PaypalForm
                            disabled={!allServicesAccepted}
                            clientId={gateways?.paypal.client_id}
                            services={services}
                            date={date}
                            submitBooking={submitBooking}
                        />
                    )}

                    {!!depositText && <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(depositText) }} />}
                </>
            ) : (
                <Typography>Invalid service configuration.</Typography>
            )}
        </>
    );
};

export default WidgetPaymentForm;
