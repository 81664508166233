import useAuth from '../../../../../../hooks/useAuth';
import { Box, CircularProgress, Typography } from '@mui/material';
import MessagesComponent from '../../../../../MessagesComponent';
import { useAppSelector } from '../../../../../../hooks/redux';
import useMessagingEnabled from '../../../../../../hooks/use-messaging-enabled';

const AppointmentMessages = () => {
    const { user } = useAuth();
    const { selectedEvent } = useAppSelector((state) => state.calendar);
    const isMessagingEnabled = useMessagingEnabled();

    if (!user) {
        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }
    return isMessagingEnabled ? (
        <>{selectedEvent && <MessagesComponent customer={selectedEvent.customer} />}</>
    ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <Typography variant="h3" align="center" sx={{ my: 'auto', px: 3 }}>
                <div>Messaging is not enabled for this organization.</div>
                <div>Please contact support.</div>
            </Typography>
        </Box>
    );
};

export default AppointmentMessages;
