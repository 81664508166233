import * as Yup from 'yup';

const paypalSchema = Yup.object().shape(
    {
        client_id: Yup.mixed().when('client_secret', {
            is: (v: unknown) => !!v,
            then: Yup.string()
                .trim()
                .typeError('Paypal Client Id is required when Paypal Client Secret is set')
                .required('Paypal Client Id is required when Paypal Client Secret is set'),
            otherwise: Yup.mixed().nullable()
        }),
        client_secret: Yup.mixed().when('client_id', {
            is: (v: unknown) => !!v,
            then: Yup.string()
                .trim()
                .typeError('Paypal Secret is required when PaypalClient Id is set')
                .required('Paypal Secret is required when PaypalClient Id is set'),
            otherwise: Yup.mixed().nullable()
        })
    },
    [['client_id', 'client_secret']]
);

const authorizeSchema = Yup.object().shape(
    {
        api_login_id: Yup.mixed()
            .nullable()
            .when('transaction_key', {
                is: (v: unknown) => !!v,
                then: Yup.string()
                    .trim()
                    .typeError('Authorize.net API Login ID is required when Authorize.net Transaction Key is set')
                    .required('Authorize.net API Login ID is required when Authorize.net Transaction Key is set')
            }),
        transaction_key: Yup.mixed()
            .nullable()
            .when('api_login_id', {
                is: (v: unknown) => !!v,
                then: Yup.string()
                    .trim()
                    .typeError('Authorize.net Transaction Key is required when Authorize.net API Login ID is set')
                    .required('Authorize.net Transaction Key is required when Authorize.net API Login ID is set')
            })
    },
    [['api_login_id', 'transaction_key']]
);

const stripeSchema = Yup.object().shape(
    {
        secret_key: Yup.mixed()
            .nullable()
            .when('publishable_key', {
                is: (v: unknown) => !!v,
                then: Yup.string()
                    .trim()
                    .typeError('Stripe Secret Key is required when Stripe Publishable Key is set')
                    .required('Stripe Secret Key is required when Stripe Publishable Key is set')
            }),
        publishable_key: Yup.mixed()
            .nullable()
            .when('secret_key', {
                is: (v: unknown) => !!v,
                then: Yup.string()
                    .trim()
                    .typeError('Stripe Publishable Key is required when Stripe Secret Key is set')
                    .required('SStripe Publishable Key is required when Stripe Secret Key is set')
            })
    },
    [['publishable_key', 'secret_key']]
);

export const squareUpSchema = Yup.object().shape(
    {
        application_id: Yup.string().when(['access_token', 'location_id'], {
            is: (access_token: unknown, location_id: unknown) => !!access_token || !!location_id,
            then: Yup.string()
                .typeError('Application Id is required with Access Token or Location Id')
                .trim()
                .required('Application Id is required with Access Token or Location Id'),
            otherwise: Yup.string().nullable().notRequired()
        }),
        access_token: Yup.string().when(['application_id', 'location_id'], {
            is: (application_id: unknown, location_id: unknown) => !!application_id || !!location_id,
            then: Yup.string()
                .typeError('Access Token is required with Application Id or Location Id')
                .trim()
                .required('Access Token is required with Application Id or Location Id'),
            otherwise: Yup.string().nullable().notRequired()
        }),
        location_id: Yup.string().when(['application_id', 'access_token'], {
            is: (application_id: unknown, access_token: unknown) => !!application_id || !!access_token,
            then: Yup.string()
                .typeError('Location Id is required with Application Id or Access Token')
                .trim()
                .required('Location Id is required with Application Id or Access Token'),
            otherwise: Yup.string().nullable().notRequired()
        })
    },
    [
        ['access_token', 'location_id'],
        ['application_id', 'location_id'],
        ['application_id', 'access_token']
    ]
);

const validationSchema = Yup.object().shape({
    integrations: Yup.object().shape({
        cc_processor: Yup.string().trim().oneOf(['', 'stripe', 'authorize_net', 'square_up']).label('CC Processor'),
        paypal: paypalSchema,
        authorize_net: Yup.mixed().when('cc_processor', {
            is: 'authorize_net',
            then: authorizeSchema
        }),
        stripe: Yup.mixed().when('cc_processor', {
            is: 'stripe',
            then: stripeSchema
        }),
        square_up: Yup.mixed().when('cc_processor', {
            is: 'square_up',
            then: squareUpSchema
        })
    })
});

export default validationSchema;
