import customerAPI from '../services/CustomerService';
import appointmentAPI from '../services/AppointmentService';
import { useEffect, useMemo } from 'react';

interface IUseSearchOptions {
    search: string;
    page?: number;
    perPage?: number;
}

const useSearch = ({ search, page = 1, perPage = 5 }: IUseSearchOptions) => {
    const {
        data: customerData,
        isLoading: customersLoading,
        isFetching: customersFetching
    } = customerAPI.useFetchAllCustomersQuery(
        {
            per_page: perPage,
            page,
            search
        },
        { skip: !search }
    );

    const {
        data: appointmentsData,
        isLoading: appointmentsLoading,
        isFetching: appointmentsFetching
    } = appointmentAPI.useFetchAllPaginatedAppointmentsQuery(
        {
            per_page: perPage,
            page,
            customerInfo: search
        },
        { skip: !search }
    );

    const total = useMemo(() => {
        const customers = customerData?.meta.total ?? 0;
        const apps = appointmentsData?.meta.total ?? 0;

        return customers + apps;
    }, [customerData, appointmentsData]);

    const loading = useMemo(
        () => customersLoading || appointmentsLoading || appointmentsFetching || customersFetching,
        [customersLoading, customersFetching, appointmentsFetching, appointmentsLoading]
    );

    useEffect(() => {
        console.log('customersLoading', customersLoading);
        console.log('customersFetching', customersFetching);
        console.log('appointmentsFetching', appointmentsFetching);
        console.log('appointmentsLoading', appointmentsLoading);
        console.log('loading', loading);
    }, [appointmentsFetching, appointmentsLoading, customersFetching, customersLoading, loading]);

    return { customerData, appointmentsData, loading, total };
};

export default useSearch;
