import { useMemo, useContext } from 'react';
import { AbilityContext } from '../../../utils/roles/Can';

const useCalendarRestrictions = () => {
    const ability = useContext(AbilityContext);

    const canCreate = useMemo(() => ability.can('create', 'appointment'), [ability]);
    const canDelete = useMemo(() => ability.can('delete', 'appointment'), [ability]);
    const canUpdate = useMemo(() => ability.can('update', 'appointment'), [ability]);
    const canView = useMemo(() => ability.can('view', 'appointment'), [ability]);

    return { canCreate, canDelete, canUpdate, canView };
};

export default useCalendarRestrictions;
