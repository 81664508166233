import { FC, useMemo, useCallback } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import StickyFormFooter from '../../../ui-component/form/sticky-form-footer';
import CommonDrawer, { useCommonSettingsDrawerPx } from '../../../ui-component/CommonSettingsDrawer';
import { useFormik } from 'formik';
import { squareUpSchema } from '../../account-profile/payment-integrations-scheme';

type SquareFormDataType = {
    application_id?: string | null;
    access_token?: string | null;
    location_id?: string | null;
};

type SquareFormProps = {
    open: boolean;
    onClose: () => void;
    data?: SquareFormDataType | null;
    onSave: (data: SquareFormDataType) => void;
};

const labels: Record<string, string> = {
    access_token: 'Access Token',
    application_id: 'Application Id',
    location_id: 'Location Id'
};

const SquareForm: FC<SquareFormProps> = ({ open, onClose, data, onSave }) => {
    const containerXSpacing = useCommonSettingsDrawerPx();
    const initialValues = useMemo(
        () => ({
            application_id: data?.application_id ?? '',
            access_token: data?.access_token ?? '',
            location_id: data?.location_id ?? ''
        }),
        [data]
    );

    const { values, handleSubmit, resetForm, handleChange, handleBlur, errors, touched } = useFormik<SquareFormDataType>({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        initialValues,
        onSubmit: (formData) => {
            onSave(formData);
            onClose();
        },
        validationSchema: squareUpSchema
    });

    const handleClose = useCallback(() => {
        onClose();
        resetForm();
    }, [resetForm, onClose]);

    return (
        <CommonDrawer open={open} title="Square Details" onClose={handleClose}>
            <Box
                component="form"
                noValidate
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%'
                }}
                onSubmit={handleSubmit}
            >
                <Stack spacing={3}>
                    {(['application_id', 'access_token', 'location_id'] as const).map((item) => (
                        <TextField
                            key={item}
                            id={item}
                            name={item}
                            label={labels[item] ?? item}
                            value={values[item]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            error={!!touched[item] && !!errors[item]}
                            helperText={touched[item] ? errors[item] : null}
                        />
                    ))}
                </Stack>
                <StickyFormFooter containerXSpacing={containerXSpacing}>
                    <Button className="forcedBg" color="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </StickyFormFooter>
            </Box>
        </CommonDrawer>
    );
};

export default SquareForm;
