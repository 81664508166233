import { IConversationDetails } from '../models/IAppointment';
import customerAPI from '../services/CustomerService';
import { useAppDispatch } from './redux';
import { useCallback } from 'react';

const useMessagesCache = () => {
    const dispatch = useAppDispatch();

    const updateMessagesCache = useCallback(
        (customerId: number, res: IConversationDetails) => {
            dispatch(
                customerAPI.util.updateQueryData(
                    'getSingleConversation',
                    {
                        customerId,
                        conversationId: res.id
                    },
                    () => res
                )
            );

            dispatch(
                customerAPI.util.updateQueryData('getConversationsList', customerId, (prev) =>
                    prev.some((conv) => conv.id === res.id) ? prev.map((c) => (c.id === res.id ? res : c)) : [...prev, res]
                )
            );
        },
        [dispatch]
    );

    return { updateMessagesCache };
};

export default useMessagesCache;
