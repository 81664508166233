import { useCallback, useMemo, useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import { Typography, Box } from '@mui/material';

// assets
import { INotification } from 'models/INotification';
import moment from 'moment';
import EllipsisTypography from '../../../../ui-component/optimized-text-fields/EllipsisTypography';
import useNotificationItem from '../../../../hooks/useNotificationItem';
import { useAppSelector } from '../../../../hooks/redux';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
    itemAction: {
        cursor: 'pointer',
        padding: '10px 20px',
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary[200]
        }
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

type NotificationItemProps = {
    notification: INotification;
    setOpen: (arg: boolean) => void;
};

const NotificationItem = ({ notification, setOpen }: NotificationItemProps) => {
    const { selectedLocation } = useAppSelector((state) => state.calendarFilter);
    const theme = useTheme();
    const classes = useStyles();
    const [isClicked, setIsClicked] = useState(false);
    const { isMessage, customerName, notificationData, handleClickNotification } = useNotificationItem(notification);

    const handleClickAppointment = useCallback(async () => {
        if (!isClicked) {
            setIsClicked(true);
            handleClickNotification().finally(() => {
                setOpen(false);
                setIsClicked(false);
            });
        }
    }, [handleClickNotification, isClicked, setOpen]);

    const selectedTimeZone = selectedLocation?.time_zone;
    const notification_time_zone = useMemo(() => selectedTimeZone ?? moment.tz.guess(), [selectedTimeZone]);
    const notificationTime = useMemo(() => (isMessage ? notification.created_at : notification?.data?.start_at), [isMessage, notification]);

    return (
        <Box
            className={classes.itemAction}
            onClick={handleClickAppointment}
            sx={{ background: notification.read_at ? '#fff' : theme.palette.primary.light }}
        >
            <Typography variant="subtitle1">{notificationData.title}</Typography>
            <Box>
                <EllipsisTypography variant="subtitle2" text={customerName} ml={0} sx={{ maxWidth: '100%' }} />
                <Typography variant="subtitle2">
                    {!isMessage ? 'on ' : null}
                    {notification_time_zone
                        ? moment(notificationTime).tz(notification_time_zone).format('M/D/YY [at] hh:mm A (z)')
                        : moment(notificationTime).format('M/D/YY [at] hh:mm A')}
                </Typography>
            </Box>
        </Box>
    );
};

export default NotificationItem;
